// typography styles here
.es-heading--one {
  @apply text-ebony;
  font-size: 4rem;
  line-height: 105%;
  letter-spacing: -3px;
  font-weight: 600;
  color: $ebony;

  @include media('<phone') {
    font-size: 2.9rem;
  }
}

.es-heading--two {
  font-size: 2rem;
  color: $ebony;
  line-height: 130%;

  @include media('<phone') {
    font-size: 1.7rem;
  }
}

.es-heading--three {
  font-size: 1.5rem;
  line-height: 130%;
  color: $ebony;

  @include media('<phone') {
    font-size: 1.3rem;
    line-height: 140%;
  }

  &.has-line {
    position: relative;
    padding-bottom: 1rem;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;

      height: 4px;
      width: 64px;

      background: $primary;
    }
  }
}

.es-heading--three--light {
  @extend .es-heading--three;

  color: $white;
}

.es-title--small {
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  color: $primary-red;

  &.is-black {
    color: $ebony;
  }
}

.es-title--small--light {
  @extend .es-title--small;

  color: $white;
}

.es-text {
  font-size: 1rem;
  line-height: 160%;

  @include media('<phone') {
    font-size: 0.9375rem;
  }
}

.es-text--lg {
  font-size: 1.125rem;
  line-height: 170%;

  @include media('<phone') {
    font-size: 16px;
  }
}

.es-text--xl {
  font-size: 1.5rem;
  line-height: 150%;
  letter-spacing: -0.03em;

  @include media('<phone') {
    font-size: 1.3rem;
    line-height: 140%;
  }
}

.es-link--more {
  font-size: 1rem;
  color: $primary;
  line-height: 1.5;
  font-weight: 500;
  cursor: pointer;
}