.es-card {
  &__link {
    display: block;

    &:hover {
      .es-card__image {
        transform: scale(1.1);
      }

      .es-card__title::after {
        width: 100%;
      }
    }
  }

  &__image-container {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
  }

  &__image {
    @extend %image;

    transition: transform 2s $ease-out-expo;
  }

  &__icon {
    width: 56px;
    height: 56px;
    object-fit: contain;

    @include media('<phone') {
      width: 48px;
      height: 48px;
    }
  }

  &__body {
    margin-top: 1.5rem;

    @include media('<phone') {
      margin-top: 0.75rem;
    }
  }

  &__title {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 140%;
    color: $ebony;
    position: relative;
    padding-bottom: 15px;
    display: inline-block;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;

      height: 3px;
      width: 32px;

      background: $primary;

      transition: width 0.2s ease;
    }

    @include media('<phone') {
      font-size: 1rem;
      font-weight: 600;
      padding-bottom: 10px;

      &::after {
        height: 3px;
        width: 24px;
      }
    }
  }

  &__description {
    font-size: 1rem;
    line-height: 160%;
    color: $stormGray;
    margin-top: 1rem;

    @include media('<phone') {
      font-size: 0.875rem;
    }
  }
}

.es-card--product {
  position: relative;

  .es-card__image-container {
    padding-top: 110%;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(
        rgba($primary-dark, 0),
        rgba($primary-dark, 1)
      );
    }
  }

  .es-card__body {
    position: absolute;
    left: 18px;
    bottom: 24px;
    margin-top: 0;
  }

  .es-card__title {
    font-size: 1rem;
    font-weight: 500;
    color: $white;
    position: relative;
    padding-bottom: 0;
    display: inline-block;

    &::after {
      content: none;
    }
  }
}

.es-card--horizontal {
  @apply flex gap-x-4 items-center;

  .es-card__image-container {
    position: relative;
    width: 60px;
    height: 60px;
    padding-top: 0;
    overflow: hidden;
  }
  .es-card__image {
  }

  .es-card__title {
    font-size: 1rem;
    font-weight: 500;
    padding-bottom: 0;
    display: inline-block;

    &::after {
      content: none;
    }
  }
}
