$primary: #1275de;
$primary-red: #e6232a;
$dblue: #071129;
$primary-dark: #212830;

$ebony: #0d0c22;
$stormGray: #6e6d7a;

$dark-gray: #484848;
$light-gray: #8c9196;
$border-color: #dddddd;
$offwhite: #f3f3f4;
$white: #ffffff;

$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$expo-ease-out: cubic-bezier(0.19, 1, 0.22, 1);
$expo-ease-in-out: cubic-bezier(1, 0, 0, 1);