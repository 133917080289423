.es-section {
  margin-bottom: 8.75rem;

  @include media('<phone') {
    margin-bottom: 5rem;
  }
}

.es-section--small {
  margin-bottom: 7.5rem;

  @include media('<phone') {
    margin-bottom: 4rem;
  }
}

.es-section--bg {
  @extend .es-section;

  padding: 7.5rem 0;

  @include media('<phone') {
    padding: 4rem 0;
  }
}

.es-block-content--large {
  max-width: 760px;
  margin: 0 auto;

  @include media('<tablet') {
    max-width: 570px;
  }
}

.es-section--no-margin {
  margin-bottom: 0;
}
