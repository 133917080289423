.es-footer {
  background: $primary-dark;
  font-size: 0.875rem;

  &__top {
    @apply container grid grid-cols-12;
    padding-top: 3.5rem;
    padding-bottom: 8.75rem;

    @include media('<phone') {
      padding-bottom: 6rem;
    }
  }

  &__logo {
    height: 80px;
    width: auto;
    object-fit: contain;
  }

  &__title {
    font-size: 1rem;
    font-weight: 600;
    color: $white;
    margin-bottom: 0.5rem;
  }

  &__link {
    @apply text-gray-400 hover:text-white;

    font-size: 0.875rem;
    transition: color 0.15s ease;
  }

  &__social {
    @apply flex gap-x-2;
  }

  &__social__link {
    display: inline-block;
  }

  &__social__icon {
    width: 2rem;
    height: 2rem;
    fill: rgb(156, 163, 175);
    transition: fill 0.15s ease;

    &:hover {
      fill: $white;
    }
  }

  &__icon-content {
    @apply flex text-gray-400 gap-x-2;
  }

  &__bot {
    @apply container flex justify-between py-6 text-sm;
    color: rgba($white, 0.5);

    @include media('<phone') {
      @apply flex-col gap-y-2;
    }
  }
}
