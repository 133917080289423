.es-sidenav {
  &__list {
    @apply flex flex-col gap-y-3;
  }

  &__title {
    font-size: 1rem;
    line-height: 160%;
    color: $ebony;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  &__item {
    padding: 6px 0;
  }

  &__link {
    font-size: 0.875rem;
    color: $light-gray;
    display: inline-block;
    transition: color 0.15s ease;
    line-height: 1.4;

    &--active {
      color: $primary;
      font-weight: 600;
      pointer-events: none;
    }

    &:hover:not(.es-sidenav__link--active) {
      color: $ebony;
    }
  }
}
