.es-form {
  &__label {
    display: block;
    font-size: 0.875rem;
    color: $ebony;
    margin-bottom: 12px;
  }
  &__input {
    @apply block w-full px-3 py-2.5 border-borderColor focus:border-primary rounded;
  }
}
