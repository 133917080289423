.es-product {
  &__content {
    // max-width: 430px;
  }
  &__image-container {
    position: relative;
    width: 100%;
    padding-top: 75%;
    overflow: hidden;
    border-radius: 0.5rem;
  }

  &__image {
    @extend %image;
  }
}
