.es-contact {
  &__social {
    @apply flex gap-x-2;

    &__link {
      display: inline-block;
    }

    &__icon {
      width: 2rem;
      height: 2rem;
      fill: $stormGray;
      transition: fill 0.15s ease;

      &:hover {
        fill: $ebony;
      }
    }
  }
}
