.es-hero {
  position: relative;
  display: flex;
  align-items: center;

  @include media('<tablet') {
    flex-direction: column-reverse;
  }
  @include media('>=tablet') {
    min-height: calc(100vh - 80px);
  }

  &__image-container {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;

    @include media('<tablet') {
      position: relative;
      width: 100%;
      padding-top: 75%;
    }
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;

    object-fit: cover;
    object-position: center;
  }
}

.es-brand-logo {
  height: auto;
  width: auto;

  max-width: 150px;
  max-height: 64px;

  @include media('<phone') {
    max-width: 130px;
    max-height: 50px;
  }
}
